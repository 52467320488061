
export enum Side {
  T = "T",
  CT = "CT",
}

export type PlayerGizmos = 'Default' | 'Minimal';

export interface InventoryItem {
  weaponName: string,
  weaponClass: string,
  ammoInMagazine: number,
  ammoInReserve: number,
}

export interface PlayerFrameData {
  match_id: string | undefined, // This is dynamically generated for analyse view
  roundNum: number | undefined, // This is dynamically generated for analyse view
  steamID: number,
  name: string,
  shortName: string, // This is dynamically generated
  team: string,
  side: string,
  x: number,
  y: number,
  z: number,
  viewX: number,
  viewY: number,
  isAlive: boolean,
  hp: number,
  armor: number,
  activeWeapon: string,
  inventory: InventoryItem[],
  hasDefuse: boolean,
  hasBomb: boolean,
  hasHelmet: boolean,
  isDefusing: boolean,
  isBlinded: boolean,
  blindedForTicks: number, // This is dynamically generated
  nextProjectileThrowTick: number | undefined, // This is dynamically generated
  prevProjectileThrowTick: number | undefined, // This is dynamically generated
  cash: number,
}

export interface TeamFrameData {
  players: PlayerFrameData[];
}

export interface ProjectileData {
  projectileType: string,
  uniqueID: number,
  x: number,
  y: number,
  z: number,
}

export interface SmokeData {
  grenadeEntityID: number,
  startTick: number,
  x: number,
  y: number,
  z: number,
}

export interface FireData {
  uniqueID: number,
  x: number,
  y: number,
  z: number,
}

export interface BombData {
  x: number,
  y: number,
  z: number,
}

export interface FrameData {
  tick: number,
  t: TeamFrameData,
  ct: TeamFrameData,
  projectiles: ProjectileData[],
  smokes: SmokeData[],
  fires: FireData[],
  bomb: BombData,
  bombPlanted: boolean,
  clockTime: string,
}

export interface GrenadeData {
  throwTick: number,
  destroyTick: number,
  grenadeType: string,
  grenadeX: number,
  grenadeY: number,
  grenadeZ: number,
  entityId: number,
}

export interface DamageData {
  tick: number,
  attackerSteamID: number,
  victimSteamID: number,
  attackerX: number,
  attackerY: number,
  attackerZ: number,
  victimX: number,
  victimY: number,
  victimZ: number,
  attackerViewX: number,
  attackerViewY: number,
  victimViewX: number,
  victimViewY: number,
  weaponClass: string,
  weapon: string,
  distance: number,
}

export interface KillData {
  matchId: string | undefined, // This is dynamically generated for analyse view
  roundNum: number | undefined, // This is dynamically generated for analyse view
  attackerShortName: string | undefined, // This is dynamically generated for analyse view
  victimShortName: string | undefined, // This is dynamically generated for analyse view
  tick: number,
  attackerSteamID: number,
  attackerName: string,
  attackerTeam: string,
  attackerSide: string,
  victimSteamID: number,
  victimName: string,
  victimTeam: string,
  victimSide: string,
  attackerX: number,
  attackerY: number,
  attackerZ: number,
  victimX: number,
  victimY: number,
  victimZ: number,
  attackerViewX: number,
  attackerViewY: number,
  victimViewX: number,
  victimViewY: number,
  weaponClass: string,
  weapon: string,
  distance: number,
}

export interface WeaponFireData {
  tick: number,
  playerSteamID: number,
  playerX: number,
  playerY: number,
  playerZ: number,
  playerViewX: number,
  playerViewY: number,
  weapon: string,
  weaponClass: string,
}

export interface RoundPlayerData {
  steamID: number,
  playerName: string,
}

export interface RoundTeamData {
  teamName: string,
  players: RoundPlayerData[],
}

export interface RoundData {
  matchId: string | undefined, // This is dynamically generated for analyse view
  mapName: string | undefined, // This is dynamically generated for analyse view
  tTeamKey: string | undefined, // This is dynamically generated for analyse view
  ctTeamKey: string | undefined, // This is dynamically generated for analyse view
  roundNum: number,
  tTeam: string,
  ctTeam: string,
  tBuyType: string,
  ctBuyType: string,
  winningSide: string,
  winningTeam: string,
  startTick: number,
  endTick: number,
  bombPlantTick: number,
  tSide: RoundTeamData,
  ctSide: RoundTeamData,
  frames: FrameData[];
  grenades: GrenadeData[];
  weaponFires: WeaponFireData[];
  damages: DamageData[];
  kills: KillData[];
  roundEndReason: string,
  cs_version: string | undefined,
  parser_version: string | undefined,
}

export interface HLTVRoundData {
  winner_team_index: number,
  winning_side: Side,
  teamA_side: Side,
  teamB_side: Side,
  overtime: boolean,
}

export interface HLTVRoundsData {
  [key: string]: HLTVRoundData,
}

export interface HLTVMapData {
  rounds: HLTVRoundsData;
}

export interface HLTVMatchMapsData {
  [key: string]: HLTVMapData,
}

export interface HLTVMatchData {
  maps: HLTVMatchMapsData,
  teamA_name: string,
  teamB_name: string,
  date: string,
  event_key: string,
  teamA_key: string,
  teamB_key: string,
}

export interface RoundMetadata {
  match_id: string;
  mapname: string;
  roundnum: number;
  t_team_key: string;
  ct_team_key: string;
  t_team: string;
  ct_team: string;
  winning_side: string;
  winning_team_key: string;
  round_end_reason: string;
  t_score: number;
  ct_score: number;
  t_team_flag: string | undefined;
  ct_team_flag: string | undefined;
  cs_version: string | undefined;
}

export interface RoundsMetadata {
  [matchId: string]: RoundMetadata[];
}

export interface Playlist {
  rounds: RoundsMetadata;
  external: boolean;
  playlist_id?: string | undefined;
  name?: string | undefined;
  team_key?: string | undefined;
  match_id?: string | undefined;
  side?: string | undefined;
  mapname?: string | undefined;
}