import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { fetchFromApi, fetchFromS3 } from "../utils/api";
import { Playlist, RoundData, RoundMetadata, Side } from "../utils/types";
import * as zip from "@zip.js/zip.js";
import AlertPopup from "./AlertPopup";
import MatchUnlockTimer from "./MatchUnlockTimer";
import UpgradeInfo from "./UpgradeInfo";
import { clearFaultyFrames, generatePlayerShortNames } from "../utils/teams";
import Team from "./Team";
import PaintButton from "./PaintButton";
import RecordingButton from "./RecordingButton";
import SettingsButton from "./SettingsButton";
import FilterButton from "./FilterButton";
import Timeline from "./Timeline";
import { useNavigate, useParams } from "react-router-dom";
import MapView from "./MapView";
import RecordingGizmo from "./RecordingGizmo";
import { captureAndExportGIF, innerToOuterRect } from "../utils/recording";
import Killfeed from "./Killfeed";
import { copyToClipboard } from "../utils/copypaste";
import { getNextOrPrevRound, getPlaylistLength, getRound, getRoundIndex, removeRound } from "../utils/playlist";
import { isLocked, getHasSubscription, getSubscription } from "../utils/premium";
import RoundPickerButtons from "./RoundPickerButtons";
import MapPicker from "./MapPicker";
import RoundPickerToolbar from "./RoundPickerToolbar";
import PlaylistButton from "./PlaylistButton";
import { fixHasBombCs2, fixWeaponInventoryCs2 } from "../utils/weapons";
import Clock from "./Clock";
import { post_usage } from "../utils/usage";


type ReplayerProps = {
  playlist: Playlist;
};

interface RoundResponse {
  round_url: string,
}

const initializeCurrentMetadata = (playlist: Playlist | undefined, mapName: string | undefined, roundName: string | undefined): RoundMetadata | undefined => {
  if (!playlist) return undefined;

  // if both mapName and roundName are set
  if (mapName && roundName) {
    for (const rounds of Object.values(playlist.rounds)) {
      const round = rounds.find(r => r.mapname === mapName && r.roundnum === parseInt(roundName));
      if (round) return round;
    }
  }

  // if only mapName is set
  else if (mapName) {
    for (const rounds of Object.values(playlist.rounds)) {
      const round = rounds.find(r => r.mapname === mapName);
      if (round) return round;
    }
  }

  // Default to the first round of the playlist
  return Object.values(playlist.rounds)[0][0];
};

const Replayer = ({ playlist }: ReplayerProps) => {
  const { id, mapName, roundName } = useParams();

  const [showAlert, setShowAlert] = useState<React.ReactNode | undefined>(undefined);
  const [roundCache, setRoundCache] = useState<{ [key: string]: RoundData }>({});
  const [mostRecentAutoplayRound, setMostRecentAutoplayRound] = useState<RoundData | undefined>(undefined);
  const [currentMetadata, setCurrentMetadata] = useState<RoundMetadata | undefined>(() => initializeCurrentMetadata(playlist, mapName, roundName));
  const [currentData, setCurrentData] = useState<RoundData | undefined>(undefined);
  const [currentTick, setCurrentTick] = useState<number | undefined>(undefined);
  const [playing, setPlaying] = useState<boolean>(localStorage.getItem('autoplay') === "true" || localStorage.getItem('autoplay') === null);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [upperView, setUpperView] = useState<boolean>(true);
  const [hoveredPlayer, setHoveredPlayer] = useState<number | undefined>(undefined);
  const [fetchingRound, setFetchingRound] = useState<boolean>(false);
  const [currentlyFetchingRounds, setCurrentlyFetchingRounds] = useState<Set<string>>(new Set());

  const [activePaintColor, setActivePaintColor] = useState<number[]>([192, 192, 192]);
  const [painting, setPainting] = useState<boolean>(false);
  const [paintUndoCount, setPaintUndoCount] = useState<number>(0);
  const [paintClearCount, setPaintClearCount] = useState<number>(0);
  const [readyToRecord, setReadyToRecord] = useState<boolean>(false);
  const [recording, setRecording] = useState<boolean>(false);
  const [recordingRect, setRecordingRect] = useState<number[]>([20, 20, 80, 80]);
  const [recordingRange, setRecordingRange] = useState<number[]>([0, 0]);
  const [recordingProgress, setRecordingProgress] = useState<number>(0);

  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");

  const [csVersion, setCsVersion] = useState<string>("csgo");

  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const gifRecordElementRef = useRef<HTMLDivElement | null>(null);

  const wideLayout = width > 1024;
  const showRoundPicker = playlist.external && Object.values(playlist.rounds).flat().length < 2 ? false : true;
  const useTallFooter = wideLayout && showRoundPicker && playlist.match_id !== undefined || !wideLayout;

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  useEffect(() => { post_usage('player_window') }, []);

  // Handle loaded round
  useEffect(() => {
    if(!currentMetadata) {
      setCurrentData(undefined);
      return;
    }

    const round_id = `${currentMetadata.match_id}.${currentMetadata.mapname}.${currentMetadata.roundnum}`;
    if(roundCache[round_id]) {
      setCurrentData(roundCache[round_id]);
      if((mostRecentAutoplayRound === undefined || mostRecentAutoplayRound.matchId !== currentMetadata.match_id || mostRecentAutoplayRound.mapName !== currentMetadata.mapname || mostRecentAutoplayRound.roundNum !== currentMetadata.roundnum)) {
        const autoplayback = localStorage.getItem('autoplay') === "true" || localStorage.getItem('autoplay') === null;
        setPlaying(autoplayback);
        setCurrentTick(roundCache[round_id].frames[0].tick);
        setMostRecentAutoplayRound(roundCache[round_id]);
        setFetchingRound(false);
      }
    }
  }, [roundCache, currentMetadata]);

  useEffect(() => {
    if (!currentMetadata) {
      return;
    }

    const round_id = `${currentMetadata.match_id}.${currentMetadata.mapname}.${currentMetadata.roundnum}`;
    if (roundCache[round_id]) {
      return;
    }

    setCurrentlyFetchingRounds((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(round_id);
      return newSet;
    });
    setFetchingRound(true);
    
    const round_url = `/round?key=${currentMetadata.match_id}&map=de_${currentMetadata.mapname}&round=${currentMetadata.roundnum}`;
    fetchFromApi(round_url)
      .then((response) => response.json())
      .then((responseObject: RoundResponse) => {
        fetchFromS3(responseObject.round_url, { method: 'get', referrerPolicy: 'no-referrer' })
          .then((map_url_response) => map_url_response.blob())
          .then(async blob => {
            const zipFileReader = new zip.BlobReader(blob);
            const zipReader = new zip.ZipReader(zipFileReader);
            for (const entry of await zipReader.getEntries()) {
              const zipTextWriter = new zip.TextWriter();
              const strContent = await entry.getData(zipTextWriter);
              const jsonObj: RoundData = JSON.parse(strContent);
              jsonObj.matchId = currentMetadata.match_id;
              jsonObj.mapName = currentMetadata.mapname;
              jsonObj.roundNum = currentMetadata.roundnum;
              clearFaultyFrames(jsonObj);
              generatePlayerShortNames(jsonObj);
              setCsVersion(jsonObj.cs_version || "csgo");
              if (jsonObj.cs_version === "cs2") {
                fixWeaponInventoryCs2(jsonObj);
                fixHasBombCs2(jsonObj);
              }
              setRoundCache(prevData => ({
                ...prevData,
                [round_id]: jsonObj,
              }));
              setCurrentlyFetchingRounds((prevSet) => {
                const newSet = new Set(prevSet);
                newSet.delete(round_id);
                return newSet;
              });
            }
          })
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [currentMetadata, roundCache]);

  // Playback
  useEffect(() => {
    let animationFrameId: number | null = null;
    let lastTime: number | undefined = undefined;

    const update = (time: number) => {
      animationFrameId = requestAnimationFrame(update);
      if (playing) {
        const deltaTime = lastTime ? time - lastTime : 0;
        const tickRate = csVersion === "cs2" ? 64 : 128;

        setCurrentTick(prevTick => prevTick !== undefined ? prevTick + (tickRate / 1000) * playbackSpeed * deltaTime : undefined);
        lastTime = time;
      } else {
        lastTime = undefined;
      }
    };
    update(0);
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [playing, playbackSpeed, csVersion]);

  // Change to next round if tick is over the last frame
  useEffect(() => {
    if (currentData && currentTick && !fetchingRound && currentTick > currentData.frames[currentData.frames.length - 1].tick) {
      toggleNextOrPreviousRound(1);
    }
  }, [currentData, currentTick]);

  useEffect(() => {
    if (!readyToRecord || !currentTick)
      return;

    if (currentData) {
      const tickRate = csVersion === "cs2" ? 64 : 128;
      const startTick = currentData.frames[0].tick;
      const endTick = currentData.frames[currentData.frames.length - 1].tick;
      const matchLength = endTick - startTick;

      setPlaying(false);

      // Set recording range from current tick to 5 seconds ahead or end of round
      setRecordingRange([
        currentTick,
        Math.min(currentTick + 5 * tickRate, endTick)
      ]);
    }
  }, [readyToRecord, csVersion]);

  function togglePainting() {
    if (painting) {
      post_usage('painting');
      setPaintClearCount(paintClearCount + 1);
    }
    setPainting(!painting);
  }

  function togglePlaybackSpeed(faster: boolean) {
    const playbackSpeedOptions = [0.5, 1, 1.5, 2, 4, 8];
    const currentIndex = playbackSpeedOptions.indexOf(playbackSpeed);
    let newIndex = faster ? currentIndex + 1 : currentIndex - 1;
    if (newIndex < 0 || newIndex >= playbackSpeedOptions.length) {
      newIndex = 0;
    }
    setPlaybackSpeed(playbackSpeedOptions[newIndex]);
  }

  function toggleUpperView(selectedMap: string) {
    if ((selectedMap === 'vertigo' || selectedMap === 'nuke') && !readyToRecord && !recording) {
      setUpperView(prevUpperView => !prevUpperView);
    }
  }

  const onStartRecording = (matchID: string, mapName: string, roundNum: number, startTick: number, endTick: number, frameRate: number, speed: number, quality: number, rect: number[]) => {
    if (!gifRecordElementRef.current || !currentTick) {
      return;
    }
    const transformedRect = innerToOuterRect(rect, [0, 0, gifRecordElementRef.current.clientWidth, gifRecordElementRef.current.clientHeight]);
    post_usage('recording');
    setRecording(true);
    setPlaying(false);
    captureAndExportGIF(
      gifRecordElementRef.current,
      startTick,
      endTick,
      frameRate,
      speed,
      quality,
      transformedRect,
      `${matchID}.${mapName}.r${roundNum.toString()}`,
      csVersion,
      setRecordingProgress,
      setCurrentTick,
      setRecording,
      setReadyToRecord,
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === " " || event.keyCode === 32) {
        setPlaying(prevAutoPlay => !prevAutoPlay);
      }
      else if (event.key === "j" || event.keyCode === 74) {
        toggleNextOrPreviousRound(-1);
      }
      else if (event.key === "k" || event.keyCode === 75) {
        toggleNextOrPreviousRound(1);
      }
      else if (event.key === "m" || event.keyCode === 77) {
        togglePlaybackSpeed(true);
      }
      else if (event.key === "n" || event.keyCode === 78) {
        togglePlaybackSpeed(false);
      }
      else if ((event.key === "ArrowLeft" && !event.shiftKey) || (event.key === "," || event.keyCode === 188)) {
        setCurrentTick(prevTick => prevTick ? prevTick - 640 : prevTick);
      }
      else if ((event.key === "ArrowRight" && !event.shiftKey) || (event.key === "." || event.keyCode === 190)) {
        setCurrentTick(prevTick => prevTick ? prevTick + 640 : prevTick);
      }
      else if (event.key === "l" || event.keyCode === 76) {
        toggleUpperView(currentMetadata?.mapname || '');
      }
      else if (event.key === "p" || event.keyCode === 80) {
        if (hasSubscription) {
          togglePainting();
        }
      }
      else if (event.key === "7" || event.keyCode === 55) {
        if (hasSubscription && !painting) {
          togglePainting();
        }
        setActivePaintColor([192, 192, 192]);
      }
      else if (event.key === "8" || event.keyCode === 56) {
        if (hasSubscription && !painting) {
          togglePainting();
        }
        setActivePaintColor([255, 0, 0]);
      }
      else if (event.key === "9" || event.keyCode === 57) {
        if (hasSubscription && !painting) {
          togglePainting();
        }
        setActivePaintColor([255, 175, 71]);
      }
      else if (event.key === "0" || event.keyCode === 48) {
        if (hasSubscription && !painting) {
          togglePainting();
        }
        setActivePaintColor([71, 203, 255]);
      }
      else if ((event.ctrlKey || event.metaKey) && (event.key === 'z' || event.keyCode === 90)) {
        event.preventDefault();
        setPaintUndoCount(prevCount => prevCount + 1);
      }
      else if (event.key === "s" || event.keyCode === 83) {
        if (hoveredPlayer !== undefined) {
          const currentFrame = currentTick ? currentData?.frames.find(frame => frame.tick > currentTick) : undefined;
          const players = currentFrame?.t.players.concat(currentFrame?.ct.players);
          const player = players?.find(player => player.steamID === hoveredPlayer);
          if (player) {
            const csgoPitch = player.viewY > 180 ? player.viewY - 360 : player.viewY;
            const csgoYaw = player.viewX > 180 ? player.viewX - 360 : player.viewX;
            const command = player ? `setpos ${player.x} ${player.y} ${player.z}; setang ${csgoPitch} ${csgoYaw} 0` : '';
            copyToClipboard(command);
            setShowAlert(<span>The setpos/setang of {player?.name} copied to clipboard!<br /><br /><code>{command}</code></span>);
          }
        } else {
          setShowAlert('Hover over a player first!');
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentTick, hoveredPlayer, painting, togglePainting, togglePlaybackSpeed, toggleUpperView]);

  function toggleNextOrPreviousRound(direction: number) {
    if (direction === 1) {
      if (currentMetadata) {
        const newRound = getNextOrPrevRound(playlist.rounds, currentMetadata, 1);
        if (newRound !== currentMetadata) {
          changePlaylistRound(getNextOrPrevRound(playlist.rounds, currentMetadata, 1));
        }
      }
    }
    else if (direction === -1) {
      if (currentMetadata) {
        const newRound = getNextOrPrevRound(playlist.rounds, currentMetadata, -1);
        if (newRound !== currentMetadata) {
          changePlaylistRound(getNextOrPrevRound(playlist.rounds, currentMetadata, -1));
        }
      }
    } else {
      console.error('Invalid direction');
    }
  }

  function changePlaylistRound(newRound: RoundMetadata) {
    const round_id = `${newRound.match_id}.${newRound.mapname}.${newRound.roundnum}`;
    const autoplayback = localStorage.getItem('autoplay') === "true" || localStorage.getItem('autoplay') === null;
      
    setReadyToRecord(false);
    setRecording(false);
    setPlaying(autoplayback);
    setCurrentMetadata(newRound);

    if(roundCache[round_id]) {    
      setCurrentTick(roundCache[round_id].frames[0].tick);
      setMostRecentAutoplayRound(roundCache[round_id]);
      setCurrentData(roundCache[round_id]);
    } else {
      setCurrentData(undefined);
    }

    setPlaying(autoplayback);
    
    navigate(`/match/${newRound.match_id}/${newRound.mapname}/${newRound.roundnum}`);
  }

  const alertPopup = showAlert &&
    <AlertPopup show={showAlert !== undefined} onClose={() => setShowAlert(undefined)}>
      <>{showAlert}</>
    </AlertPopup>

  if (currentMetadata) {
    const upgradeHeader = <MatchUnlockTimer matchId={currentMetadata.match_id} />
    const upgradeInfo = <div className="map-view-wrapper"><UpgradeInfo header={upgradeHeader} /></div>;

    const teamCT = <div className="team">
      <Team
        roundData={currentData}
        roundMetaData={currentMetadata}
        currentTick={currentTick}
        side={Side.CT}
        minimal={!wideLayout}
        hoveredPlayer={undefined}
        onPlayerBeginHover={() => { }}
        onPlayerEndHover={() => { }}
      />
    </div>

    const teamT = <div className="team">
      <Team
        roundData={currentData}
        roundMetaData={currentMetadata}
        currentTick={currentTick}
        side={Side.T}
        minimal={!wideLayout}
        hoveredPlayer={undefined}
        onPlayerBeginHover={() => { }}
        onPlayerEndHover={() => { }}
      />
    </div>

    const mapView = <div
      className="map"
      onClick={
        () => {
          if (!painting) {
            toggleUpperView(currentMetadata?.mapname || '')
          }
        }
      }
      style={{
        cursor: (currentMetadata?.mapname === 'vertigo' || currentMetadata?.mapname === 'nuke') && !painting ? 'pointer' : painting ? 'crosshair' : 'default',
        backgroundColor: 'rgb(34,34,34)'
      }}
    >
      <div ref={gifRecordElementRef} className="gif-record-element">
        <MapView
          data={currentData}
          currentTick={currentTick}
          mapName={'de_' + currentMetadata?.mapname || ''}
          hoveredPlayer={hoveredPlayer}
          upperView={upperView}
          playerGizmos={wideLayout ? "Default" : "Minimal"}
          wideLayout={wideLayout}
          activePaintColor={activePaintColor}
          paintUndoCount={paintUndoCount}
          paintClearCount={paintClearCount}
          painting={painting}
          csVersion={csVersion}
          recording={recording || readyToRecord}
          recordingRect={recording || readyToRecord ? recordingRect : undefined}
          onPlayerBeginHover={setHoveredPlayer}
          onPlayerEndHover={() => setHoveredPlayer(undefined)}
        />
      </div>
      {readyToRecord || recording ?
        <div className="gif-recorder-overlay">
          <RecordingGizmo
            recording={recording}
            recordingRect={recordingRect}
            recordingProgress={recordingProgress}
            setRecordingRect={setRecordingRect}
            onStartRecording={() => {
              onStartRecording(
                currentMetadata?.match_id || '',
                currentMetadata?.mapname || '',
                currentMetadata?.roundnum || 0,
                recordingRange[0],
                recordingRange[1],
                parseInt(localStorage.getItem('recordingFrameRate') || '10'),
                parseFloat(localStorage.getItem('recordingSpeed') || '3'),
                parseFloat(localStorage.getItem('recordingQuality') || '50'),
                recordingRect,
              );
            }}
            onCancelRecording={() => {
              setPlaying(false);
              setRecording(false);
              setRecordingProgress(0.0);
            }}
          />
        </div> : null
      }
    </div>;

    const killFeed = height > 800 ? <div className="kill-feed">
      <Killfeed
        data={currentData?.kills}
        currentTick={currentTick}
      />
    </div> : null;

    const roundPickerButtons =
      <RoundPickerButtons
        playlist={playlist}
        selectedRound={currentMetadata}
        wideMode={wideLayout}
        setSelectedRound={(selectedRound) => {
          changePlaylistRound(selectedRound);
        }}
      />

    const maps = playlist.rounds[playlist.match_id || '']?.reduce((acc: string[], round) => {
      if (!acc.includes(round.mapname)) {
        acc.push(round.mapname);
      }
      return acc;
    }, []) || [];

    const mapPicker = <MapPicker
      maps={maps}
      selectedMapName={currentMetadata?.mapname || ''}
      inRoundPicker={false}
      setSelectedMap={(mapName: string) => {
        setCurrentMetadata(playlist.rounds[playlist.match_id || '']?.find(round => round.mapname === mapName) || playlist.rounds[playlist.match_id || ''][0]);
      }}
    />;

    const roundPickerToolbar = <RoundPickerToolbar
      playlist={playlist}
      selectedRound={currentMetadata}
      currentlyFetchingRounds={currentlyFetchingRounds}
      currentlyFetchedRounds={new Set(Object.keys(roundCache))}
      mr={csVersion === 'cs2' ? 12 : 15}
      setSelectedRound={(selectedRound) => {
        changePlaylistRound(selectedRound);
      }}
    />

    const timeline = <Timeline
      data={currentData}
      currentTick={currentTick}
      setCurrentTick={(tick: number) => {
        setCurrentTick(tick);
      }}
      wideLayout={wideLayout}
      setRecordingRange={setRecordingRange}
      recordingRange={recordingRange}
      playbackSpeed={playbackSpeed}
      autoPlay={playing}
      setAutoPlay={setPlaying}
      togglePlaybackSpeed={() => togglePlaybackSpeed(true)}
      mode={readyToRecord || recording ? 'recording' : 'playback'}
    />

    const paintButton = <PaintButton
      activePaintColor={activePaintColor}
      isPainting={painting}
      onActivePaintColorchange={(paintColor) => {
        setActivePaintColor(paintColor);
      }}
      onTogglePainting={() => {
        togglePainting();
      }}
      onUndoPaint={() => {
        setPaintUndoCount(paintUndoCount + 1)
      }}
    />

    const recordingButton = <RecordingButton
      isRecording={recording}
      isActive={readyToRecord}
      onToggle={() => {
        setReadyToRecord(!readyToRecord);
      }}
    />

    const playlistButton = <PlaylistButton
      selectedRound={currentMetadata}
      onRemovedRound={(playlist_id, round_id) => {
        if (playlist && playlist_id === playlist.playlist_id) {
          const roundItem = getRound(playlist, round_id);
          if (roundItem) {
            const newPlaylist = removeRound(playlist, round_id);
            sessionStorage.setItem('playlist', JSON.stringify(newPlaylist));
            if (getPlaylistLength(newPlaylist) > 0) {
              const newRound = getNextOrPrevRound(playlist.rounds, roundItem, 1);
              changePlaylistRound(newRound);
              window.location.reload();
            } else {
              navigate('/playlists');
            }
          }
        }
      }}
    />

    const settingsButton = <SettingsButton
      onClose={() => { setCurrentTick((curr) => curr ? curr + 1 : undefined) }} // Cause render
    />
    const filterButton = <FilterButton
      onClick={() => navigate(playlist ? '/filter' : `/filter?team=${currentMetadata?.ct_team}&mapname=${currentMetadata?.mapname}`)}
    />

    const footer = wideLayout ?
      <footer style={{ height: useTallFooter ? '90px' : '53px' }}>
        <div className="d-flex footer-flex">
          {useTallFooter &&
            <div className="d-flex w-100 py-0 px-0" style={{ borderBottom: '1px dashed #444', backgroundColor: '#202020' }}>
              {roundPickerToolbar}
            </div>
          }
          <div className={`py-2 d-flex w-100 px-2`}>
            {showRoundPicker && !useTallFooter && roundPickerButtons}
            <div className={`w-100`} style={{ marginRight: wideLayout ? '20px' : '0px' }}>
              {timeline}
            </div>
            {paintButton}
            {recordingButton}
            {playlistButton}
            {settingsButton}
            {filterButton}
          </div>
        </div>
      </footer> : <footer style={{ height: '90px' }}>
        <div className="d-flex footer-flex">
          <div className="d-flex w-100 py-0 px-0" style={{ borderBottom: '1px dashed #444', backgroundColor: '#202020' }}>
            <div className="d-flex align-items-center">
              {mapPicker}
            </div>
            <div className="flex-grow-1"></div>
            <div className="d-flex align-items-center">
              {roundPickerButtons}
            </div>
          </div>
          <div className={`py-2 d-flex w-100 px-2`}>
            <div className={`w-100`} style={{ marginRight: '10px' }}>
              {timeline}
            </div>
          </div>
        </div>
      </footer>

    const locked = (!playlist.external || playlist.external && Object.values(playlist.rounds).flat().length > 1) && currentMetadata.roundnum >= (csVersion === "cs2" ? 13 : 16) && isLocked(id || "", subscription);
    const main = wideLayout ?
      <>
        {!(recording || readyToRecord) && <Clock
          currentTick={currentTick || 0}
          bombPlantTick={currentData?.bombPlantTick || undefined}
          startTick={currentData?.frames[0].tick || 0}
          csVersion={csVersion}
          areaClock={false}
          area={undefined}
        />}
        <div className="main" style={{ bottom: useTallFooter ? '106px' : '56px' }}>
          {teamCT}
          {locked ? upgradeInfo : mapView}
          {teamT}
          {killFeed}
        </div>
      </> :
      <div className="main" style={{ bottom: '106px' }}>
        {locked ? upgradeInfo : mapView}
        <div className="teams">
          {!locked && teamCT}
          {!locked && teamT}
        </div>
      </div>;


    return (
      <>
        {alertPopup}
        <div className="main-wrapper">
          {main}
        </div>
        {footer}
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default Replayer;