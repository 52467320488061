import { Col, Container, Row, Button, Spinner, Modal, Form } from "react-bootstrap";
import styles from "./Account.module.css";
import { fetchFromApi } from "../utils/api";
import { useEffect, useState } from "react";
import UpgradeButton from "../components/UpgradeButton";
import CancelButton from "../components/CancelButton";
import { Auth } from "aws-amplify";
import { getHasSubscription, isStripe } from "../utils/premium";
import CustomModalHeader from "../components/CustomModalHeader";


export interface AddMemberResponse {
  success: boolean;
  email_not_found: boolean;
  error?: string;
}

export interface OrgMember {
  user_id: string;
  email: string;
}

export interface AccountData {
  user_id: string;
  email: string;
  subscription: string;
  team: string;
  org_name: string;
  org_admin: boolean;
  org_members: OrgMember[];
}

const Account = () => {
  const [account, setAccount] = useState<AccountData | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [removedMemberEmail, setRemovedMemberEmail] = useState<string | undefined>(undefined);
  const [removingMember, setRemovingMember] = useState<boolean>(false);
  const [newMemberEmail, setNewMemberEmail] = useState<string | undefined>(undefined);
  const [savingNewMember, setSavingNewMember] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (account === undefined) {
      fetchFromApi('/account')
        .then((response) => response.json() as Promise<AccountData>)
        .then((data) => {
          setAccount(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [account]);

  function isOrgAdmin() {
    return account?.org_admin;
  }

  const addNewMember = (email: string) => {
    if (!email) {
      return;
    }
    setErrorMessage(undefined);
    setSavingNewMember(true);
    fetchFromApi('/add_member', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email": email })
    })
      .then((response) => response.json() as Promise<AddMemberResponse>)
      .then((data) => {
        if (data.success) {
          setAccount(undefined);
          setShowAddMemberModal(false);
        } else {
          if(data.email_not_found) {
            setErrorMessage("Email not found! An account with this email must be created first.");
          } else {
            setErrorMessage(data.error);
          }
        }
        setSavingNewMember(false);
      })
      .catch((error) => {
        setSavingNewMember(false);
        setErrorMessage(`An undefined error occurred while adding the new member.`);
        console.error("Error:", error);
      });
  }

  const removeMember = (email: string) => {
    if (!email) {
      return;
    }
    setErrorMessage(undefined);
    setRemovingMember(true);
    fetchFromApi('/remove_member', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email": email })
    })
      .then((response) => response.json() as Promise<AddMemberResponse>)
      .then((data) => {
        if (data.success) {
          setAccount(undefined);
          setShowRemoveMemberModal(false);
        } else {
          if(data.email_not_found) {
            setErrorMessage(`Account for ${email} not found!`);
          } else {
            setErrorMessage(data.error);
          }
        }
        setRemovingMember(false);
      })
      .catch((error) => {
        setRemovingMember(false);
        setErrorMessage(`An undefined error occurred while removing the member.`);
        console.error("Error:", error);
      });
  }

  const handleShowNewMemberModal = (): void => {
    setNewMemberEmail(undefined);
    setErrorMessage(undefined);
    setSavingNewMember(false);
    setShowAddMemberModal(true);
  }
  const handleCloseNewMemberModal = (): void => setShowRemoveMemberModal(false);

  const handleShowRemoveMemberModal = (email: string): void => {
    setErrorMessage(undefined);
    setRemovingMember(false);
    setRemovedMemberEmail(email);
    setShowRemoveMemberModal(true);
  }
  const handleCloseRemoveMemberModal = (): void => setShowRemoveMemberModal(false);

  if (!isAuthenticated) {
    return (
      <div className={`container ${styles.accountContainer}`} style={{ textAlign: "center" }}>
        You must sign in to access this page.
      </div>
    );
  } else {
    return (
      <div className={`container ${styles.accountContainer}`}>
        <Container fluid>
          <Row className={styles.myrow}>
            <Col>
              <h2>Account</h2>
              <hr />
            </Col>
          </Row>
          {!account &&
            <Row className={styles.valuerow}>
              <Col sm={4} className={styles.value} id="user-email">
                <span style={{ color: '#666' }}><Spinner animation="border" size="sm" style={{ marginRight: 7 }} /> Loading...</span>
              </Col>
            </Row>
          }
          {account && <>
            <Row className={styles.valuerow}>
              <Col sm={3} className={styles.label}><i className="bi bi-envelope" style={{ marginRight: 5 }} /> Email</Col>
              <Col sm={8} className={styles.value} id="user-email">
                {account ? account.email : <span style={{ color: '#666' }}><Spinner animation="border" size="sm" style={{ marginRight: 7 }} /> Loading...</span>}
              </Col>
            </Row>
            <Row className={styles.valuerow}>
              <Col sm={3} className={styles.label}><i className="bi bi-person-badge" style={{ marginRight: 5 }} /> Account ID</Col>
              <Col sm={8} className={styles.value} id="user-id">{account?.user_id}</Col>
            </Row>
            {/* <Row className={styles.valuerow}>
              <Col sm={3} className={styles.label}><i className="bi bi-people" style={{ marginRight: 5 }} /> Team</Col>
              <Col sm={8} className={styles.value} id="team-name">{account?.org_name?.toUpperCase() || account?.team}</Col>
            </Row> */}
          </>}
          <Row className={styles.myrow}>
            <Col>
              <h2>Subscription</h2>
              <hr />
            </Col>
          </Row>
          {!account &&
            <Row className={styles.valuerow}>
              <Col sm={4} className={styles.value} id="user-email">
                <span style={{ color: '#666' }}><Spinner animation="border" size="sm" style={{ marginRight: 7 }} /> Loading...</span>
              </Col>
            </Row>
          }
          {account && <>
            <Row className={styles.valuerow}>
              <Col sm={3} className={styles.label}><i className="bi bi-receipt" style={{ marginRight: 5 }} /> Type</Col>
              <Col sm={8} className={styles.value} id="subscription"><span>{account && account.org_name ? (account.org_name.length > 0 ? `Organization (${account?.org_name.toUpperCase()})` : account.subscription) : account.subscription}</span></Col>
            </Row>
          </>}
          {account && account.subscription === 'PREMIUM' && <>
            <Row className={styles.valuerow} style={{ marginTop: '20px' }}>
              <Col sm={12} className={styles.value} id="subscription">Monthly invoices sent by Gumroad have a <span style={{ color: '#ccc' }}><strong><i>subscription settings</i></strong></span> link.<br />Use it to update your payment information or cancel your subscription.</Col>
            </Row>
            <Row className={styles.valuerow} style={{ marginTop: '20px' }}>
              <Col sm={12} className={styles.value} id="subscription">If you have any problems, please <a href="https://cs2lens.com/support">contact</a> us immediately.</Col>
            </Row>
          </>}
          {isOrgAdmin() &&
            <Row className={styles.myrow}>
              <Col>
                <h2>Organization Members</h2>
                <hr />
              </Col>
            </Row>}
          {isOrgAdmin() && account?.org_members.map((member, index) => (
            <Row className={styles.valuerow} key={index}>
              <Col sm={5} className={styles.label}>{member.email}</Col>
              <Col sm={7} className={styles.value} id="user-id"><Button onClick={() => handleShowRemoveMemberModal(member.email)} variant="outline-danger" size="sm" disabled={member.user_id === account.user_id}><i className="bi bi-trash" style={{ marginRight: 5 }} /> Remove</Button></Col>
            </Row>
          ))}
          {isOrgAdmin() &&
            <Row className={styles.valuerow} key={"new member"} style={{ marginTop: '12px', marginBottom: '50px' }}>
              <Col sm={12} className={styles.value} id="user-id"><Button onClick={() => handleShowNewMemberModal()} variant="primary" size="sm"><i className="bi bi-person-plus" style={{ marginRight: 5 }} /> New member</Button></Col>
            </Row>
          }

        </Container>
        <Modal show={showAddMemberModal} onHide={handleCloseNewMemberModal}>
          <div onKeyDown={(e) => e.stopPropagation()}>
            <CustomModalHeader />
            <Modal.Body>
              <Row>
                <Col sm={12} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                    Add New Member
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', marginTop: 0, marginBottom: 10 }}>
                    Note: This is not an invite system!<br/>The new member must have created an account first.
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col sm={2} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                    E-mail
                  </Form.Label>
                </Col>
                <Col sm={10} className="d-flex align-items-center">
                  <Form.Control
                    min={1}
                    max={64}
                    value={newMemberEmail}
                    placeholder="E-mail of an existing user"
                    autoFocus={true}
                    onChange={(e) => {
                      setNewMemberEmail(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {savingNewMember && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
              {errorMessage && <span style={{ color: 'red', marginRight: 10 }}>{errorMessage}</span>}
              <Button variant="success" onClick={() => addNewMember(newMemberEmail || '')} disabled={!newMemberEmail || savingNewMember}>
                Add Member
              </Button>
              <Button variant="outline-secondary" onClick={() => setShowAddMemberModal(false)}>
                Cancel
              </Button>
            </Modal.Footer>
          </div>
        </Modal>

        <Modal show={showRemoveMemberModal} onHide={handleCloseRemoveMemberModal}>
          <div onKeyDown={(e) => e.stopPropagation()}>
            <CustomModalHeader />
            <Modal.Body>
                <Row>
                <Col sm={12} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                    Removing member from organization
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', marginTop: 0, marginBottom: 0 }}>
                    You want to remove <code>{removedMemberEmail}</code>?
                  </Form.Label>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {removingMember && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
              {errorMessage && <span style={{ color: 'red', marginRight: 10 }}>{errorMessage}</span>}
              <Button variant="danger" onClick={() => removeMember(removedMemberEmail || '')} disabled={removingMember}>
                Remove
              </Button>
              <Button variant="outline-secondary" onClick={handleCloseRemoveMemberModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
};

export default Account;
