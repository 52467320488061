import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FrameData, RoundData } from "../utils/types";
import RangeSlider from 'react-bootstrap-range-slider';
import React, { useState } from "react";
import Range from 'rc-slider';
import styles from './FooterButton.module.css';
import 'rc-slider/assets/index.css';
import { setSourceMapRange } from "typescript";
import { Timer } from "./Timer";

const Timeline = ({
  data,
  currentTick,
  recordingRange,
  autoPlay,
  playbackSpeed,
  wideLayout,
  setCurrentTick,
  setRecordingRange,
  setAutoPlay,
  togglePlaybackSpeed,
  mode = "playback"
}: {
  data: RoundData | undefined,
  currentTick: number | undefined,
  recordingRange: number[] | undefined,
  autoPlay: boolean,
  playbackSpeed: number,
  wideLayout: boolean,
  setCurrentTick: (currentTick: number) => void
  setAutoPlay: (autoPlay: boolean) => void
  setRecordingRange: (recordingRange: number[]) => void
  togglePlaybackSpeed: () => void
  mode?: "playback" | "recording"
}) => {
  const [autoPlayOnMouseDown, setAutoPlayOnMouseDown] = useState(false);

  const startTick = data ? data.frames[0].tick : 0;
  const endTick = data ? data.frames[data.frames.length - 1].tick : 0;
  const tickStart: number = data ? Math.max(...data.frames.map((f) => f.tick).filter((t) => t <= (currentTick ? currentTick : 0) && t >= startTick && t < endTick)) : 0;
  
  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const renderSlider = () => {
    if (mode === "playback") {
      return (
        <RangeSlider
          value={data && currentTick ? currentTick : 0}
          onMouseUp={(event: any) => {
            setCurrentTick(parseInt(event.target.value))
            setAutoPlay(autoPlayOnMouseDown);
          }}
          onMouseDown={(event: any) => {
            setAutoPlayOnMouseDown(autoPlay)
            setAutoPlay(false);
          }}
          onChange={changeEvent => {
            const newValue = parseInt(changeEvent.target.value);
            if(newValue !== currentTick) {
              setCurrentTick(newValue);
            }
          }}
          min={data ? data.frames[0].tick : 0}
          max={data ? data.frames[data.frames.length - 1].tick : 1}
          tooltip="off"
          variant="info"
        />
      );
    } else if (mode === "recording" && recordingRange) {
      const handleStyle = {
        borderColor: 'red',
        backgroundColor: 'red',
        opacity: 1,
        width: '20px',
        height: '20px',
        marginTop: '-8px'
      };
      return (
        <div style={{ width: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Range
            range
            value={[recordingRange[0], recordingRange[1]]}
            onChange={(value: number | number[]) => {
              if (Array.isArray(value)) {
                // Check which handle is being moved
                if (value[0] === recordingRange[0]) {
                  setCurrentTick(value[1]);
                } else {
                  setCurrentTick(value[0]);
                }
                setRecordingRange(value);
              }
            }}
            min={data ? data.frames[0].tick : 0}
            max={data ? data.frames[data.frames.length - 1].tick : 1}
            allowCross={false}
            trackStyle={[{ backgroundColor: 'red' }]}
            handleStyle={[handleStyle, handleStyle]}
            railStyle={{ backgroundColor: 'lightgray' }}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex w-100">
        {wideLayout &&
          <OverlayTrigger
            placement="top"
            delay={{ show: 300, hide: 0 }}
            overlay={
              <Tooltip id={`tooltip-playback-speed`}>
                Playback speed
              </Tooltip>
            }
          >
            <Button
              variant={'outline-secondary'}
              size="sm"
              className={styles.dimmedButton}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
              onClick={togglePlaybackSpeed}
              onKeyDown={handleKeyDown}
            >
              x{playbackSpeed}
            </Button>
          </OverlayTrigger>
        }
        <Button
          disabled={mode === "recording"}
          variant={'outline-secondary'}
          size="sm"
          className={`${styles.button} ${styles.playButton}`}
          onClick={() => setAutoPlay(!autoPlay)} onKeyDown={handleKeyDown}
        >
          <div style={{ position: 'relative', fontSize: '1.2em', left: autoPlay ? '0px' : '1px' }}>
            {autoPlay ? <i className="bi bi-pause-fill"></i> : <i className="bi bi-play-fill"></i>}
          </div>
        </Button>
        <div className="px-3 flex-grow-1">
          {renderSlider()}
        </div>
        <div className="round-timer">
          <Timer
            startTick={startTick}
            currentTick={currentTick || 0}
            bombPlantedTick={data?.bombPlantTick ||undefined}
            tickRate={data?.cs_version === "cs2" ? 64 : 128}
          />
        </div>
      </div>
    </>
  );
};

export default Timeline;