import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { getHasSubscription, getSubscription } from '../utils/premium';
import UpgradeInfoModal from './UpgradeInfoModal';

function HamburgerMenu() {
  const [showModal, setShowModal] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

  const handleUploadClick = () => {
    if (hasSubscription) {
      window.location.href = '/upload';
    } else {
      setShowModal(true);
    }
  }

  const handleCS2UploadClick = () => {
    if (hasSubscription) {
      window.location.href = '/upload-cs2';
    } else {
      setShowModal(true);
    }
  }

  const handleMyUploadsClick = () => {
    if (hasSubscription) {
      sessionStorage.setItem('match-filter-team', 'Any');
      sessionStorage.setItem('match-filter-map', 'Any');
      sessionStorage.setItem('match-filter-event', 'user-upload');
      sessionStorage.setItem('match-filter-date', (new Date()).toISOString());
      window.location.href = '/';
    } else {
      setShowModal(true);
    }
  }

  const handleAnalyseClick = () => {
    sessionStorage.removeItem('playlist-analyse');
    localStorage.removeItem('playlist-analyse');
    window.location.href = `/analyse?returnTo=${encodeURIComponent(window.location.pathname + window.location.search)}`
  }

  const handleAccountClick = () => {
    window.location.href = '/account';
  }

  const handleSupportClick = () => {
    window.location.href = '/support';
  }

  const handleAboutClick = () => {
    window.location.href = '/about';
  }

  const handlePlaylistsClick = () => {
    window.location.href = '/playlists';
  }

  const handleUpgradeClick = () => {
    setShowModal(true);
  }

  return (
    <DropdownButton
      variant="outline-secondary"
      className="borderless-dropdown-button"
      style={{ marginRight: '5px' }}
      align="end"
      title={<><i className='bi bi-list' style={{ color: '#ddd', fontSize: '1.2em', position: 'relative', top: '2px' }}></i></>}
      id="dropdown-menu-align-end"
    >
      <Dropdown.Item onClick={handleAnalyseClick}>
        <i className='bi bi-cup-hot' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Analyser</span>
      </Dropdown.Item>
      <Dropdown.Item href="/filter"><i className='bi bi-search' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Filter</span>
      </Dropdown.Item>
      <Dropdown.Item onClick={handlePlaylistsClick}><i className='bi bi-star-fill' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Playlists</span>
      </Dropdown.Item>
      <Dropdown.Divider />
      {/* <Dropdown.Item onClick={handleUploadClick}><i className='bi bi-box-arrow-up' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Upload <span style={{ color: '#777', fontSize: '0.8em', marginLeft: '2px' }}>CS:GO</span></span>
      </Dropdown.Item> */}
      <Dropdown.Item onClick={handleCS2UploadClick}><i className='bi bi-box-arrow-up' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Upload <span style={{ color: '#777', fontSize: '0.8em', marginLeft: '2px' }}>CS2</span></span>
      </Dropdown.Item>
      <Dropdown.Item onClick={handleMyUploadsClick}><i className='bi bi-list' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Uploads</span>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleAccountClick}><i className='bi bi-person-fill' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Account</span>
      </Dropdown.Item>
      <Dropdown.Item onClick={handleSupportClick}><i className='bi bi-chat-quote-fill' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>Support</span>
      </Dropdown.Item>
      <Dropdown.Item onClick={handleAboutClick}><i className='bi bi-info-circle-fill' style={{ color: '#888', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#ddd' }}>About</span>
      </Dropdown.Item>
      {(!hasSubscription || subscription === 'BASIC') && <>
        <Dropdown.Item onClick={handleUpgradeClick}><i className='bi bi-star-fill' style={{ color: '#8f8', marginRight: '8px', fontSize: '0.9em' }} />
          <span style={{ color: '#8f8' }}>Upgrade</span>
        </Dropdown.Item>
      </>}
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout}><i className='bi bi-door-open' style={{ color: '#aaa', marginRight: '8px', fontSize: '0.9em' }} />
        <span style={{ color: '#888' }}>Logout</span>
      </Dropdown.Item>
      <UpgradeInfoModal show={showModal} handleCloseModal={() => setShowModal(false)} />
    </DropdownButton>
  );
}

export default HamburgerMenu;
