import { Playlist, RoundMetadata, RoundsMetadata } from "./types";
import { fetchRoundsMetadata } from "./rounds";
import { fetchFromApi } from "./api";

export function getFlatPlaylist(rounds: RoundsMetadata): RoundMetadata[] {
  // Transform the playlist into an ordered array
  const orderedRounds: RoundMetadata[] = [];

  // Reverse alphabetical order of matchIds
  const orderedMatchIds = Object.keys(rounds).sort().reverse();

  for (const matchId of orderedMatchIds) {
    const orderedRoundsForMatch = rounds[matchId].sort((a, b) => {
      if (a.mapname < b.mapname) return -1;
      if (a.mapname > b.mapname) return 1;
      return a.roundnum - b.roundnum;
    });
    
    orderedRounds.push(...orderedRoundsForMatch);
  } 

  return orderedRounds;
}

export function getPlaylistLength(playlist: Playlist): number {
  const orderedRounds: RoundMetadata[] = getFlatPlaylist(playlist.rounds);
  return orderedRounds.length;
}

export function getNextOrPrevRound(rounds: RoundsMetadata, currentRound: RoundMetadata, direction: number): RoundMetadata {
  // Transform the playlist into an ordered array
  const orderedRounds: RoundMetadata[] = getFlatPlaylist(rounds);

  // Find the index of the current round
  const currentIndex = orderedRounds.findIndex((round) => round.match_id === currentRound.match_id && round.mapname === currentRound.mapname && round.roundnum === currentRound.roundnum);

  // Handle edge cases
  if (currentIndex === 0 && direction === -1) {
      return orderedRounds[0];
  } else if (currentIndex === orderedRounds.length - 1 && direction === 1) {
      return orderedRounds[orderedRounds.length - 1];
  } else {
      return orderedRounds[currentIndex + direction];
  }
}

export function getPlaylistFromRounds(rawRounds: RoundsMetadata): Playlist {
  const finalPlaylist: Playlist = {
    rounds: rawRounds,
    external: false,
    side: '',
  };

  const sortedPlaylist = sortPlaylist(finalPlaylist);
  return sortedPlaylist;
}

export const sortPlaylist = (playlist: Playlist): Playlist => {
  const originalRounds = playlist.rounds;
  const sortedKeys = Object.keys(originalRounds).sort().reverse();

  // Create a new rounds object with sorted keys
  const sortedRounds: RoundsMetadata = {};
  for (const key of sortedKeys) {
    // Sort the individual rounds within each match by roundnum
    sortedRounds[key] = [...originalRounds[key]].sort((a, b) => a.roundnum - b.roundnum);
  }

  // Create a new playlist object with the sorted rounds
  const sortedPlaylist: Playlist = {
    ...playlist, // Copies other properties like team_key, match_id, etc.
    rounds: sortedRounds,
  };

  return sortedPlaylist;
};

export const getRoundIndex = (playlist: Playlist, currentRound: RoundMetadata): number => {
    // Transform the playlist into an ordered array
    const orderedRounds: RoundMetadata[] = getFlatPlaylist(playlist.rounds);

    // Find the index of the current round
    return orderedRounds.findIndex((round) => round.match_id === currentRound.match_id && round.mapname === currentRound.mapname && round.roundnum === currentRound.roundnum);
}

export const getRound = (playlist: Playlist, round_id: string): RoundMetadata | undefined => {
  const orderedRounds: RoundMetadata[] = getFlatPlaylist(playlist.rounds);
  return orderedRounds.find((round) => `${round.match_id}.${round.mapname}.${round.roundnum}` === round_id);
}

export const removeRound = (playlist: Playlist, round_id: string): Playlist => {
  const originalRounds = playlist.rounds;
  const match_id = round_id.split('.')[0] + '.' + round_id.split('.')[1] + '.' + round_id.split('.')[2] + '.' + round_id.split('.')[3];
  const mapname = round_id.split('.')[4];
  const roundnum = parseInt(round_id.split('.')[5])

  // Filter out the round to be removed
  const filteredRounds: RoundsMetadata = Object.fromEntries(
    Object.entries(originalRounds).map(([key, value]) => {
      if (key === match_id) {
        return [key, value.filter((round) => !(round.mapname === mapname && round.roundnum === roundnum))];
      } else {
        return [key, value];
      }
    })
  );

  // Create a new playlist object with the filtered rounds
  const filteredPlaylist: Playlist = {
    ...playlist,
    rounds: filteredRounds,
  };

  return filteredPlaylist;
};

export const cleanEmptyMatches = (playlist: Playlist): Playlist => {
  const originalRounds = playlist.rounds;
  
  // Filter out match keys where the rounds array is empty
  const cleanedRounds: RoundsMetadata = Object.fromEntries(
    Object.entries(originalRounds).filter(([_, value]) => value.length !== 0)
  );

  // Create a new playlist object with the cleaned rounds
  const cleanedPlaylist: Playlist = {
    ...playlist,
    rounds: cleanedRounds,
  };

  return cleanedPlaylist;
};

export function createPlaylist(roundsArray: RoundMetadata[]): Playlist {
  const rounds: RoundsMetadata = {};

  roundsArray.forEach(round => {
    if (!rounds[round.match_id]) {
      rounds[round.match_id] = [];
    }
    rounds[round.match_id].push(round);
  });

  const playlist: Playlist = {
    rounds: rounds,
    external: false,
  };

  return playlist;
}

export function countTotalRounds(playlist: Playlist): number {
  let totalRounds = 0;
  
  for (const matchId in playlist.rounds) {
    totalRounds += playlist.rounds[matchId].length;
  }

  return totalRounds;
}

async function fetchPlaylist(match_id: string, external?: boolean): Promise<Playlist | undefined> {
  return fetchRoundsMetadata(match_id)
    .then((response) => {
      if (response) {
        const playlist: Playlist = {
          rounds: response,
          match_id: match_id,
          external: external ? external : false,
        };
        return playlist;
      } else {
        throw new Error('No rounds metadata found');
      }
    });
}

async function fetchRoundsOfMatchId(matchId: string, mapname: string) {
  const response = await fetchFromApi(`/rounds?match_id=${matchId}&sides=BOTH&map=${mapname}`);
  const responseRounds: RoundsMetadata = await response.json();
  return responseRounds;
}

export async function fetchRoundsOfMatchIds(matchIds: string[], mapname: string) {
  // Create an array of promises for each fetch operation
  const fetchPromises = matchIds.map(match_id => fetchRoundsOfMatchId(match_id, mapname));

  // Wait for all fetch operations to complete and collect their results
  const roundsData = await Promise.all(fetchPromises);

  // Combine all fetched data into a single object
  const combinedRounds = roundsData.reduce((acc, rounds, index) => {
    const matchId = matchIds[index];
    if (Object.keys(rounds).length > 0) {
      acc[matchId] = rounds[matchId];
    } else {
      acc[matchId] = [];
    }
    return acc;
  }, {});

  // Return the combined rounds data
  return combinedRounds;
}