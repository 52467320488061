import React, { useEffect } from 'react';
import { Spinner } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import Replayer from '../components/Replayer';
import { fetchRoundsMetadata } from '../utils/rounds';
import { Playlist } from '../utils/types';
import { post_usage } from '../utils/usage';


const MatchLoader = () => {
  const [playlist, setPlaylist] = React.useState<Playlist | undefined>(sessionStorage.getItem('playlist') ? JSON.parse(sessionStorage.getItem('playlist') || '[]') : undefined);
  const { id, mapName, roundName } = useParams();

  useEffect(() => {
    if(playlist && id && !playlist.rounds[id] && Object.keys(playlist.rounds).length > 0) {
      setPlaylist(undefined);
    } else if(!playlist && id) {
      fetchRoundsMetadata(id)
        .then((roundsMetadata) => {
          if(roundsMetadata) {
            if(id.includes('esea')) {
              post_usage('match_esea');
            } else if(id.includes('upload')) {
              post_usage('match_user_upload');
            } else {
              post_usage('match_hltv');
            }
            const fetchedPlaylist: Playlist = {
              rounds: roundsMetadata,
              match_id: id,
              external: false,
            };
            setPlaylist(fetchedPlaylist);
          }
        }
      );
    }
  }, [id, playlist]);

  if(playlist) {
    if(Object.keys(playlist.rounds).length === 0) {
      return <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <span className="loading-label">Page not found 😞</span>
      </div>;
    }
    return <Replayer 
      playlist={playlist}
    />;
  } else {
    return <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <span><Spinner animation="border" /><span className="loading-label">Loading...</span></span>
    </div>;
  }
};

export default MatchLoader;