import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'react-bootstrap';
import styles from './UploadDropZone.module.css';

interface UploadDropZoneProps {
  onChange?: (acceptedFiles: File[]) => void;
}

const UploadDropZone: React.FC<UploadDropZoneProps> = ({ onChange }) => {
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [fileName, setFileName] = useState<string | null>(null); // add this line

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFileName(acceptedFiles[0].name); // add this line
    }

    if (onChange) {
      onChange(acceptedFiles);
    }
  }, [onChange]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles['is-drag-active'] : ''}`}>
      <input {...getInputProps()} />
      {
        fileName ?
        <p>Selected: <code>{fileName}</code></p> :
        <p>Drag 'n' drop <code>.dem</code>, <code>.bz2</code>, <code>.gz</code> or <code>.zip</code> file here</p>
      }
      {fileErrorMessage && <div className={styles.error}>{fileErrorMessage}</div>}
    </div>
  )
};

export default UploadDropZone;


