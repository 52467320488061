export const Timer = ({
  startTick,
  currentTick,
  bombPlantedTick,
  tickRate,
} : {
  startTick: number,
  currentTick: number,
  bombPlantedTick: number | undefined,
  tickRate: number,
}) => {
  // Helper function to convert total seconds to mm:ss format
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  if (bombPlantedTick === undefined || bombPlantedTick > currentTick) {
    // Bomb not planted - counting down from 01:55
    const elapsedTicks = currentTick - startTick;
    const elapsedSeconds = Math.floor(elapsedTicks / tickRate);
    const remainingTime = Math.max(0, 115 - elapsedSeconds); // 01:55 in seconds is 115 seconds
    
    return <span style={{color: remainingTime === 0 ? '#555' : '#ddd'}}>{formatTime(remainingTime)}</span>;
  } else {
    // Bomb planted - counting down from 00:40
    const elapsedTicksSinceBombPlanted = currentTick - bombPlantedTick;
    const elapsedSecondsSinceBombPlanted = Math.floor(elapsedTicksSinceBombPlanted / tickRate);
    const remainingTime = Math.max(0, 40 - elapsedSecondsSinceBombPlanted); // 00:40 in seconds is 40 seconds
    return <span style={{color: '#db7'}}>{formatTime(remainingTime)}</span>;
  }
};