import React, { useState } from 'react';
import styles from './AnalyseRoundsList.module.css'; // Adjust the import path to your CSS module
import { getColorByPercentage, getFormattedTime } from '../utils/math';
import { CachedProjectiles } from '../utils/projectile';
import { utilityItemsSvg } from '../utils/weapons';
import TeamIcon from './TeamIcon';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Playlist, RoundMetadata, RoundsMetadata } from '../utils/types';
import { createPlaylist } from '../utils/playlist';

interface AnalyseRoundsListProps {
  selectedRounds: RoundMetadata[] | undefined;
  invertSelectedRounds: boolean;
  currentPlaylist: Playlist,
  totalRoundCount: number,
  mapName: string;
  csVersion: string;
  onRemoveRound: (roundId: string) => void;
  onHoverRound: (roundId: string | undefined) => void;
  onClickRound: (roundId: string) => void;
  onInvertToggle(): void;
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

const AnalyseRoundsList: React.FC<AnalyseRoundsListProps> = ({ selectedRounds, invertSelectedRounds, currentPlaylist, totalRoundCount, mapName, csVersion, onRemoveRound, onHoverRound, onClickRound, onInvertToggle }) => {
  const navigate = useNavigate();
  const [hoveredRound, setHoveredRound] = useState<string | undefined>(undefined);

  if (!selectedRounds || Object.keys(selectedRounds).length === 0) {
    return null;
  }

  function onHandleReplayClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (selectedRounds) {
      const newPlaylist = createPlaylist(selectedRounds);

      sessionStorage.setItem('playlist', JSON.stringify(newPlaylist));
      const firstMatchId = Object.keys(newPlaylist.rounds)[0];
      const firstRoundNum = newPlaylist.rounds[firstMatchId][0].roundnum;
      if (firstMatchId && firstRoundNum !== undefined) {
        e.preventDefault();
        window.open(`/match/${firstMatchId}/${mapName}/${firstRoundNum}`, '_blank');
      }
    }
  }

  const handleClickRound = (roundId: string, ctrlKey: boolean, altKey: boolean) => {
    const r = Object.values(selectedRounds).flat().find(r => `${r.match_id}.${r.mapname}.${r.roundnum}` === roundId);
    if (!r) return;

    if (ctrlKey) {
      sessionStorage.removeItem('playlist');
      window.open(`/match/${r.match_id}/${r.mapname}/${r.roundnum}`, '_blank')
    } else if (altKey) {
      console.log('Removing round', roundId);
      onRemoveRound(roundId);
      setHoveredRound(undefined);
    } else {
      onClickRound(roundId);
    }
  }

  const handleHoverRound = (key: string | undefined) => {
    setHoveredRound(key);
    onHoverRound(key);
  }

  let flattenedRounds = Object.values(selectedRounds).flat();

  // Sort the array first by match_id, then by roundnum
  flattenedRounds.sort((a, b) => {
    if (a.match_id === b.match_id) {
      return a.roundnum - b.roundnum; // Subsort by round number if match IDs are the same
    }
    return b.match_id.localeCompare(a.match_id); // Sort by match ID
  });

  const selectedRoundCount = flattenedRounds.length;
  const selectedRoundPercentage = Math.round((selectedRoundCount / totalRoundCount) * 100);

  return (
    <div className={styles.scrollableContainer}>
      <table className={styles.roundsTable}>
        <thead>
          <tr>
            <th>Selected rounds {selectedRoundPercentage < 100 && selectedRoundPercentage > 0 && <button className={`btn btn-sm ${invertSelectedRounds ? styles.invertButtonInverted : styles.invertButton}`} onClick={() => onInvertToggle()}><i className="bi bi-arrow-down-up" style={{ marginRight: '2px' }} /> Invert</button>}</th>
            <th className={styles.centeredCell}>Rnd</th>
          </tr>
        </thead>
        <tbody>
          {flattenedRounds.map((roundMetaData) => {
            const key = `${roundMetaData.match_id}.${roundMetaData.mapname}.${roundMetaData.roundnum}`;
            return (
              <tr key={key}
                onMouseUp={(e) => handleClickRound(key, e.ctrlKey, e.altKey)}
                onMouseEnter={() => handleHoverRound(key)}
                onMouseLeave={() => handleHoverRound(undefined)}
                className={styles.roundsRow}
              >
                <td className={styles.teamCell}>
                  <div>
                    <TeamIcon
                      name={currentPlaylist.team_key !== roundMetaData.ct_team_key ? roundMetaData.ct_team_key : roundMetaData.t_team_key}
                      size={16}
                    />
                    {roundMetaData.ct_team}
                  </div>
                </td>
                <td className={styles.centeredCell}>{zeroPad(roundMetaData.roundnum, 2)}</td>
              </tr>
            );
          })}
          <tr key={"counts-projectile-table"}>
            <td colSpan={5} className={styles.totalCountsRow}>
              <span className={styles.countNumber}>{Object.keys(selectedRounds).length}</span> rounds (<span style={{ color: getColorByPercentage(selectedRoundPercentage) }} className={styles.percentageNumber}>{selectedRoundPercentage}%</span>)
              {invertSelectedRounds && <span className={styles.invertedText}>Inverted</span>}
            </td>
          </tr>
          <tr key={"replay-button-for-projectile-table"} className={styles.infoFooter}>
            <td className={styles.replayButtonRow} colSpan={5}>
              <Button
                style={{
                  position: "relative",
                  height: 38,
                  paddingLeft: '20px',
                  paddingRight: '26px',
                }}
                className="btn-sm"
                onClick={onHandleReplayClick}
              >
                <i className="bi bi-play-fill" style={{ marginRight: '4px' }}></i>Replay all
              </Button>
            </td>
          </tr>
          <tr key={"info-for-projectile-table"} className={styles.infoFooter}>
            <td className={styles.infoFooter} colSpan={5} style={{ paddingTop: '20px' }}>
              <div>
              ALT + click to remove an item
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AnalyseRoundsList;